import * as React from "react";
import get from "lodash/get";
import find from "lodash/find";
import { cx } from "@emotion/css";

import * as styles from "./styles";
import { ITheme } from "../../helpers/getTheme";

export interface IPageDescription {
  translations: {
    key: string;
    phrase: string;
  }[];
  theme: ITheme;
  logo?: string;
}

export const PageDescription: React.FC<IPageDescription> = ({
  translations,
  logo,
  theme,
}) => {
  const getTranslations = (key: string, defaultValue: string) =>
    get(find(translations, ["key", key]), "phrase", defaultValue);

  const formatSubtitle = (text: string) => {
    const [firstPart, secondPart] = text.split(/\.(?=\s)/);
    return (
      <>
        {firstPart}.
        <br />
        {secondPart}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={cx(styles.wrapper, theme)}>
        {logo && <img src={logo} className={styles.logo} alt="LiveChat Logo" />}
        <div className={styles.title}>
          {getTranslations("Chatting_page_hello", "Hello")}! 👋
        </div>
        <div className={styles.subtitle}>
          {formatSubtitle(
            getTranslations(
              "Chatting_page_welcome",
              "Welcome to our chat page. We are here to answer all your questions."
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default PageDescription;

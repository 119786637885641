import get from "lodash/get";

export type ITheme = "light" | "dark";

interface IConfigResponse {
  __priv: {
    group: {
      "chat_window.new_theme.variant": ITheme;
    };
  };
}

interface IDynamicConfigError {
  type: string;
  data: { region: string };
}

interface IDynamicConfigResponse {
  error?: IDynamicConfigError;
  organization_id?: string;
  livechat?: {
    config_version: string;
  };
}

export const getTheme = (
  licenseId: number | string,
  groupId: number | string,
  isProd: boolean,
  region: string = "",
  retries: number = 0
): Promise<ITheme> => {
  const clientId = isProd
    ? "c5e4f61e1a6c3b1521b541bc5c5a2ac5"
    : "c6e4f62e2a2dab12531235b12c5a2a6b";

  const labs = isProd ? "" : "labs.";
  const ignoreActivityForCodeInstalled = "test=1";
  const regionPrefix = region ? `-${region}` : "";
  const dynamicConfigUrl = `https://api${regionPrefix}.${labs}livechatinc.com/v3.6/customer/action/get_dynamic_configuration?license_id=${licenseId}&client_id=${clientId}&${ignoreActivityForCodeInstalled}`;

  return fetch(dynamicConfigUrl)
    .then((response) => response.json())
    .then((data: IDynamicConfigResponse) => {
      if (data.error && data.error.type === "misdirected_request") {
        if (retries < 3) {
          return getTheme(
            licenseId,
            groupId,
            isProd,
            data.error.data.region,
            retries + 1
          );
        } else {
          throw new Error("Retry limit reached after misdirected requests.");
        }
      }

      const organizationId = data.organization_id;
      const configVersion = data.livechat?.config_version;
      const configUrl = `https://api${regionPrefix}.${labs}livechatinc.com/v3.5/customer/action/get_configuration?organization_id=${organizationId}&version=${configVersion}&group_id=${groupId}`;
      return fetch(configUrl)
        .then((configResponse) => configResponse.json())
        .then((configData: IConfigResponse) => {
          return get(
            configData,
            ["__priv", "group", "chat_window.new_theme.variant"],
            "light"
          );
        });
    })
    .catch((error) => {
      console.error("Failed to fetch theme configuration:", error);
      throw error;
    });
};

import * as React from "react";
import { cx } from "@emotion/css";

import { GET_LC, POWERED_BY } from "../../consts/redirect";
import { Icon, Link, Text } from "@livechat/design-system-react-components";
import "@livechat/design-system-react-components/dist/style.css";

import * as styles from "./styles";
import { ITheme } from "../../helpers/getTheme";
import { LiveChatColored } from "@livechat/design-system-icons";

interface TopBarProps {
  license: string;
  theme: ITheme;
}

export const TopBar: React.FC<TopBarProps> = ({ license, theme }) => {
  const getLiveChatUrl = GET_LC(license);

  const poweredByURl = POWERED_BY(license);

  return (
    <div className={cx(styles.topBar, theme)}>
      <Link
        bold={false}
        href={poweredByURl}
        target="_blank"
        rel="noopener noreferrer"
        className={cx(styles.poweredByLink, theme)}
      >
        <Icon size="large" source={LiveChatColored} />
        <Text className={cx(styles.poweredByText, theme)}>
          Powered by LiveChat
        </Text>
      </Link>
      <div className={styles.rightContainer}>
        <Text className={styles.title}>
          Create your free LiveChat account to start chatting with your
          customers.
        </Text>
        <Link
          bold={false}
          href={getLiveChatUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={cx(styles.getLiveChatButton, theme)}
        >
          <Text className={styles.getLiveChatText}>Get LiveChat</Text>
        </Link>
      </div>
    </div>
  );
};
